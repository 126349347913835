// Grid

$columns: 12;
$column-width: 100% / $columns;

// Quickie columns() function
@function columns($num-columns, $parent-num-columns: $columns) {
  @return $column-width * $num-columns * ($columns / $parent-num-columns);
}

// Base column classes
.column {
  width: columns(1);
}

.grid {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  > * {
    width: 100%;
    max-width: 100%;
    flex-basis: 100%;
  }

  &.right-aligned {
    flex-direction: row-reverse;
  }

  &.align-flex-start {
    align-items: flex-start;
  }
}

// Float Grid Alternative
.float-grid {
  width: 100%;
  @include clearfix;

  > * {
    width: 100%;
    float: none;
  }

  @include media-md {
    > * {
      float: left;
    }
  }
}

.grid-item {
  flex-basis: auto;
}
.one-fourth {
  max-width: 25%;
  flex-basis: 25%;
}
.one-third {
  flex-basis: 33.3333333%;
  max-width: 33.3333333%;
}
.one-half {
  flex-basis: 50%;
  max-width: 50%;
}
.two-thirds {
  flex-basis: 66.6666667%;
  max-width: 66.6666667%;
}
.three-fourths {
  flex-basis: 75%;
  max-width: 75%;
}

@include media-md {
  .grid-item {
    flex-basis: auto;
  }
  .med-one-fourth {
    max-width: 25%;
    flex-basis: 25%;
  }
  .med-one-third {
    flex-basis: 33.3333333%;
    max-width: 33.3333333%;
  }
  .med-one-half {
    flex-basis: 50%;
    max-width: 50%;
  }
  .med-two-thirds {
    flex-basis: 66.6666667%;
    max-width: 66.6666667%;
  }
  .med-three-fourths {
    flex-basis: 75%;
    max-width: 75%;
  }
  // Order classes
  @for $i from 0 through 6 {
    .med-order-#{$i} {
      order: $i;
    }
  }
}

@include media-lg {
  .grid-item {
    flex-basis: auto;
  }
  .lg-one-fourth {
    max-width: 25%;
    flex-basis: 25%;
  }
  .lg-one-third {
    flex-basis: 33.3333333%;
    max-width: 33.3333333%;
  }
  .lg-one-half {
    flex-basis: 50%;
    max-width: 50%;
  }
  .lg-two-thirds {
    flex-basis: 66.6666667%;
    max-width: 66.6666667%;
  }
  .lg-three-fourths {
    flex-basis: 75%;
    max-width: 75%;
  }
  // Order classes
  @for $i from 0 through 6 {
    .lg-order-#{$i} {
      order: $i;
    }
  }
}

// This allows js to determine breakpoint
#breakpoint-indicator {
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;

  &::after {
    content: 'xs';
    @include media-sm {
      content: 'sm';
    }
    @include media-md {
      content: 'md';
    }
    @include media-lg {
      content: 'lg';
    }
    @include media-xl {
      content: 'xl';
    }
    @include media-nav {
      content: 'nav';
    }
  }
}
